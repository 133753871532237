//import logo from './logo.svg';
import './App.css';
import Task from "./components/Task"

function App() {
  return (
   <>
   <Task/>
   </>
  );
}

export default App;
